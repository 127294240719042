<!-- テーブル定義画面のみ -->
<div class="row" *ngIf="is_dataset_edit" id="pc-edit-view" [ngClass]="table_info.getJaClassName()">
    <div class="col-lg-12" id="dataset_definition">
        <div class="shadow p-3 mb-2 row ml-1 mr-1">
            <span class="content">
                テーブル設定が完了しましたら、「テーブルを保存」ボタンをクリックしてください。もし、保存しない場合は「キャンセル」をクリックしてください。
            </span>
            <button class="btn btn-default btn-ladda btn-cancel" data-style="expand-right" [ladda]='sending' type="button" (click)="cancel()">
                <span style="color: rgba(32, 32, 32, 0.4);">
                    キャンセル
                </span>
            </button>
            <button class="btn btn-primary btn-save ml-2" data-style="expand-right" [ladda]='sending' type="submit" [ngClass]="{'btn-warning':table_info.menu.is_workflow && !IS_IFRAME_MODE}" (click)="go_edit()">
                テーブルを保存
            </button>
        </div>
        <dataset-form [is_system_table]="isEditSystemTable()" [system_table_info]="system_table_info" [data]="data" [data_lastchanged]="data.last_dirty_changed"
                      [workflow_template_a]="workflow_template_a" [workflow_template_csv]="workflow_template_csv" (csvLoaded)="onCsvLoaded($event)" [parent]="getThis()"></dataset-form>
        
        <div bsModal #settingModal="bs-modal" class="modal fade settingModal {{settingType}}" tabindex="-1" role="dialog"
             aria-labelledby="myModalLabel" [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{settingTitle}}</h4>
                        <button type="button" class="close" (click)="settingModal.hide()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row text-center" *ngIf="settingType==null">
                            <ng-container *ngFor="let type of this.settingTypes;let child_i = index">
                                <div *ngIf="(this.type.show_list!==false && this.settingType==null)"
                                     class="col-sm-6 mb-3">
                                    <button
                                        [disabled]="(settingType == null && (isExistFieldSelected() && !canConvertField(type.value))) "
                                        class="btn btn-lg btn-block btn-light-gray"
                                        type="button"
                                        (click)="selectFieldType(type)"><i class="fa fa-{{type.icon}}"></i> <span> {{
                                        type.label }}</span></button>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="this.settingType!=null && !isSidebarActive">
                            <div class="pl-4">
                                <button class="btn btn-md btn-default mb-2" (click)="changeFieldType()"><i class="fa fa-arrow-circle-left mr-1"></i>項目タイプ変更</button>
                                <div class="flex">
                                    <h5 class="">{{this.settingType != null ? getSettingTypeObj().label : ''}}</h5>
                                </div>
                                <div *ngIf=" this.settingType!=null &&   getSettingTypeObj().explain" class="text-left mt-3" [innerHTML]="getSettingTypeObj().explain">
                                </div>
                            </div>
                            <div class="pl-4 mt-4">
                                <dataset-field-add [system_table_info]="system_table_info" [data]="settingData"
                                                   [type]="settingType" [parent]="getThis()" [is_child_table]="data.raw_data['is_child_table']"
                                                   [mode]="(!!settingData.raw_data['id'])?'edit':'add'"></dataset-field-add>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.settingType!=null;else fieldTypeSettingMode" class="modal-footer">
                        <button type="button" class="btn btn-secondary text-bold" (click)="settingModal.hide()">キャンセル</button>
                        <button type="button" class="btn btn-primary text-bold"
                                (click)="addFieldData()">{{settingBtnLabel}}</button>
                    </div>
                    <ng-template #fieldTypeSettingMode>
                        <ng-container *ngIf="isExistFieldSelected()">
                            <button type="button" class="btn btn-secondary"
                                    (click)="settingType = settingData.raw_data['type']">キャンセル
                            </button>
                        </ng-container>

                    </ng-template>
                </div>
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</div>
<!-- /テーブル定義画面のみ END-->

<ng-container *ngIf="full_display_message;else NOT_DISPLAY_MESSAGE">
    <div class="row pc-edit-view" id="pc-edit-view" [ngClass]="{'iframe':IS_IFRAME_MODE,'public-form':IS_PUBLIC_FORM}" [ngClass]="table_info.getJaClassName()">
        <div class="col-lg-12">
            <div class="card" [ngClass]="{'mb-0':IS_EMBED_MODE}">
                <div class="message ">
                    {{full_display_message}}
                </div>
            </div>
        </div>

    </div>

</ng-container>

<ng-template #NOT_DISPLAY_MESSAGE>
   
    
    <ng-container *ngIf="isUnionMode()">
        
        <div class="row pc-edit-view" id="pc-edit-view" [ngClass]="setClasses()">
            <!-- textarea -->
            <div class="col-lg-12">
                <div class="card card-accent-primary">
                    <div class="card-block">
                        <div class="row">
                            <div class="col-12">
                                <!-- sample union all -->
                                <b>サンプル構文</b>
                                <pre>
                                    <code>
                                        SELECT 名前 as 名称,住所 as アドレス FROM テーブルA
                                        UNION ALL
                                        SELECT 名前 as 名称,おところ as アドレス FROM テーブルB
                                        UNION ALL
                                        SELECT 名称,住所 as アドレス FROM テーブルC

                                    </code>
                                </pre>
                                <div class="form-group">
                                    <label for="textarea-input">構文</label>
                                    <textarea id="textarea-input" name="textarea-input" rows="9" class="form-control" [(ngModel)]="this.data.raw_data['union_query']"
                                              placeholder="UNION/UNION ALLの構文を入力してください"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>
    <div class="row pc-edit-view" id="pc-edit-view" [ngClass]="setClasses()">
        
        <div class="col-lg-12" *ngIf="before_html!=undefined">
            <div class="card card-accent-primary">
                <div class="card-block" [innerHTML]="before_html | safeHtml"></div>
            </div>
        </div>
   
        <memo class="col-lg-12" [table_info]="table_info" [view_name]="'edit'"></memo>
        <div class="col-lg-12 pt-sm-2 p-0 p-md-2">
           
            <ng-container *ngIf="!is_dataset_edit && !is_from_dashboard">
                <div class="shadow p-3 mb-2 row ml-1 mr-1">
                    <span style="font-size: 13px;width: 998px;" class="content mr-5" *ngIf="!table_info?.menu?.is_workflow;else workflowContent">
                        レコードの作成が完了しましたら、「作成」ボタンをクリックしてください。もし、保存しない場合は「キャンセル」をクリックしてください。
                    </span>
                    <ng-template #workflowContent>
                        <span style="font-size: 13px;width: 818px;" class="content mr-5">
                            レコードの作成が完了しましたら、「申請」ボタンをクリックしてください。申請せずに保存をしたい場合は「下書き保存」をクリックしてください。もし、保存しない場合は「キャンセル」をクリックしてください。
                        </span>
                    </ng-template>
                    <button *ngIf="!IS_IFRAME_MODE" class="btn btn-default btn-ladda btn-cancel ml-5 mr-2" data-style="expand-right" [ladda]='sending' type="button" (click)="cancel()">
                        キャンセル
                    </button>
                    <button class="btn btn-primary btn-ladda mr-2" data-style="expand-right" [ladda]='sending' type="submit"
                    (click)="go_edit()" [ngClass]="{'btn-outline-primary':table_info?.menu?.is_workflow && !IS_IFRAME_MODE}"
                    [ngStyle]="{'border': table_info?.menu?.is_workflow && !IS_IFRAME_MOD ? '2px solid #20A8D7' : '',
                                'width' : table_info?.menu?.is_workflow && !IS_IFRAME_MOD ? '160px' : '112px',
                                'height' : '44px'
                            }"
                    >
                        <div *ngIf="isWorkflowApplyAvailable();then workflow_block;else non_workflow_block"></div>
                        <ng-template #workflow_block>
                            <ng-container *ngIf="IS_IFRAME_MODE">
                                <span>保存</span>
                            </ng-container>
                            <ng-container *ngIf="!IS_IFRAME_MODE">
                                <span>下書き保存</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #non_workflow_block>
                            <ng-container *ngIf="IS_IFRAME_MODE || IS_EMBED_MODE;else NOT_IFRAME">
                                <span>{{submit_text}}</span>
                            </ng-container>
                            <ng-template #NOT_IFRAME>
                                <span *ngIf="mode=='add' && is_dataset_edit" >テーブルを保存</span>
                                <span *ngIf="mode=='add' && !is_dataset_edit">作成</span>
                                <span *ngIf="mode=='edit'">作成</span>
                            </ng-template>
                        </ng-template>
                        
                    </button>
                    <button class="btn btn-primary btn-ladda" data-style="expand-right" style="width: 112px;height: 44px;" [ladda]='sending' type="submit"
                            (click)="workflow_apply_start()" *ngIf="isWorkflowApplyAvailable() && !IS_IFRAME_MODE">
                        <span>申請</span>
                    </button>
                </div>
            </ng-container>
            <div class="card  main-card " [ngClass]="{'mb-0':IS_EMBED_MODE,'mb-50-mb':!IS_EMBED_MODE,'card-bg-gray': _table === 'grant_group' && !is_from_dashboard }">
                <div class="card-header" *ngIf="!IS_IFRAME_MODE && !IS_EMBED_MODE">
                    <!--
                    <a href="javascript:void(0)" (click)="goList()"><i class="fa fa-th-list"></i> {{page_title}}一覧</a>&ngsp;<i class="fa fa-chevron-right ml-2" aria-hidden="true"></i> <span
                    *ngIf="mode=='edit';else IsNew">編集 (ID:{{id}})</span>
                    -->
                    <ng-template #IsNew>追加</ng-template>

                    <span class="ml-3">
                    <custom-filter-pulldown *ngIf="hasEditFilter() && !IS_IFRAME_MODE && (table_info && (table_info.grant.table_author || !table_info?.menu?.not_display_filter_view))"
                                            [mode]='"edit"'
                                            [table_info]="table_info"
                                            [filter_type]="'view'"
                                            [customFilter]="customFilter"
                                            (onEditFilter)="onEditFilter($event)"
                                            (onReset)="resetFilter()"
                                            (onSelectFilter)="selectFilter($event)"
                                            (onDeleteFilter)="onDeleteFilter($event)"

                    ></custom-filter-pulldown>
                </span>
                </div>
               
                <div *ngIf="!isUnionMode()" class="card-block" [ngClass]="{'p-0':isFieldEditMode(), 'px-0':IS_PUBLIC_FORM}">
                    
                    <tabset class="mt-2" *ngIf="is_dataset_edit;else not_dataset_edit" #modalTabs>
                        <ng-container *ngIf="dataset_edit_mode != 'dataset_field' && dataset_edit_mode != 'grant';else datasetField">
    
                            <dataset-form [is_system_table]="isEditSystemTable()" [system_table_info]="system_table_info" [data]="data" [data_lastchanged]="data.last_dirty_changed"
                            [workflow_template_a]="workflow_template_a" [workflow_template_csv]="workflow_template_csv" (csvLoaded)="onCsvLoaded($event)" [parent]="getThis()" [dataset_edit_mode]="dataset_edit_mode"></dataset-form>
                            
                        </ng-container>
    
                        <ng-template #datasetField>
                            
                            <div class="row">
            
                                <div [ngClass]="{'col-md-9':show_drag_field_list,
                                                'col-md-11':!show_drag_field_list
                                                }">
        
                                    <button *ngIf="dataset_edit_mode=='dataset_field'" class="btn btn-primary btn btn-primary pull-right mt-3 add-item-btn" type="button"
                                    (click)="openSettingModal(null,true)">
                                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 2.125C9.5 1.43359 8.94141 0.875 8.25 0.875C7.55859 0.875 7 1.43359 7 2.125V7.75H1.375C0.683594 7.75 0.125 8.30859 0.125 9C0.125 9.69141 0.683594 10.25 1.375 10.25H7V15.875C7 16.5664 7.55859 17.125 8.25 17.125C8.94141 17.125 9.5 16.5664 9.5 15.875V10.25H15.125C15.8164 10.25 16.375 9.69141 16.375 9C16.375 8.30859 15.8164 7.75 15.125 7.75H9.5V2.125Z" fill="white"/>
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;
                                        <span>項目を追加 </span>
                                    </button>
                                </div>
                                <div *ngIf="dataset_edit_mode=='dataset_field'" 
                                    [ngClass]="{'col-md-3':show_drag_field_list,
                                                'col-md-1':!show_drag_field_list
                                                }">
                                    <div class="pull-right mt-3 mr-4" (click)="toggleDragFieldList()" >
                                        <span *ngIf="show_drag_field_list">閉じる
                                            &nbsp;
                                            <svg width="4" height="11" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.85332 3.64683C4.04863 3.84214 4.04863 4.15933 3.85332 4.35464L0.85332 7.35464C0.658008 7.54995 0.34082 7.54995 0.145508 7.35464C-0.0498047 7.15933 -0.0498047 6.84214 0.145508 6.64683L2.79238 3.99995L0.14707 1.35308C-0.0482422 1.15776 -0.0482422 0.840576 0.14707 0.645264C0.342383 0.449951 0.65957 0.449951 0.854883 0.645264L3.85488 3.64526L3.85332 3.64683Z" fill="#5C5C5C" fill-opacity="0.5"/>
                                            </svg>
                                                
                                        </span>
                                        <span *ngIf="!show_drag_field_list" class="fa-l">開く
                                          
                                        </span>
                                        <svg *ngIf="!show_drag_field_list" width="5" height="11" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.14668 4.35317C-0.0486326 4.15786 -0.0486326 3.84067 0.14668 3.64536L3.14668 0.645361C3.34199 0.450049 3.65918 0.450049 3.85449 0.645361C4.0498 0.840674 4.0498 1.15786 3.85449 1.35317L1.20762 4.00005L3.85293 6.64692C4.04824 6.84224 4.04824 7.15942 3.85293 7.35474C3.65762 7.55005 3.34043 7.55005 3.14512 7.35474L0.145117 4.35474L0.14668 4.35317Z" fill="#5C5C5C" fill-opacity="0.5"/>
                                        </svg>
                                            
                                        
                                        <!-- <i  class="fa fa-chevron-left fa-left-second"></i> -->
                                    </div>
                                </div>
                            </div>
                          
                            <div class="row mt-2">
                                
                                <div *ngIf="isSidebarActive && dataset_edit_mode == 'dataset_field'" [ngClass]="{'col-3':isSidebarActive}">
                                    
                                    <div (scroll)="onScrollSidebar($event)">
                                        
                                        <label class="setting-title pl-4">{{settingTitle}}
                                            <button type="button" class="btn btn-sm btn-outline-info"
                                            (click)="toggleTableSettingOption()"
                                            [attr.aria-expanded]="!isTableSettingOptionCollapsed" aria-controls="collapseExample">
                                            <span class="option-setting-btn-text"> 追加オプション設定 </span>
                                            </button>
                                        </label>
                                        <div style="background: #F6F6F6;" class="ml-4">
                                            <div class="pl-4 pr-4">
                                                
                                                
                                                <h5 class="pt-4">{{this.settingType != null ? getSettingTypeObj().label : ''}}</h5>
                                                <div *ngIf=" this.settingType!=null &&   getSettingTypeObj().explain" class="text-left mt-3" [innerHTML]="getSettingTypeObj().explain">
                                                </div>
                                            </div>
                                            <div class="pl-4 mt-4 pr-4">
                                               
                                                <dataset-field-one [system_table_info]="system_table_info" 
                                                    [data]="settingData"
                                                    [type]="settingType" 
                                                    [parent]="getThis()" 
                                                    [is_child_table]="data.raw_data['is_child_table']"
                                                    [mode]="(!!settingData.raw_data['id']) ? 'edit' : 'add'"
                                                    [isTableSettingOptionCollapsed]="isTableSettingOptionCollapsed"
                                                   >
                                                </dataset-field-one>
                                            </div>
                                        </div>
                                        <div *ngIf="this.settingType!=null;else fieldTypeSettingMode" class="sidebar-footer">
                                            <button type="button" class="btn btn-secondary text-bold sidebar-active-cancel" (click)="this.isSidebarActive = !this.isSidebarActive">キャンセル</button>
                                            <button type="button" class="btn btn-primary text-bold add-field-data"
                                                    (click)="addFieldData()">{{settingBtnLabel}}</button>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div  cdkScrollable
                                    [ngClass]="{
                                        'col-9':!isSidebarActive,
                                        'col-6':isSidebarActive,
                                        'col-12':!isFieldEditMode() || !isDesktop,
                                        'drag-drop-edit-mode pr-0':isFieldEditMode() && isDesktop,
                                        'drag-drop-mode pr-0':isFieldEditMode() && isDesktop && show_drag_field_list,
                                        'drag_start':drag_placeholder_visible && isFieldEditMode() && isDesktop && show_drag_field_list,
                                        'pt-4':isFieldEditMode()}
                                    "
                                    [ngStyle]="getFieldListsCss()"
                                    >
                                    <div *ngIf="IS_PUBLIC_FORM && table_info" class="iframe-ex">
                                        <h2 class="iframe-title">{{table_info.menu.public_form_title}}</h2>
                                        <div class="iframe-description">{{table_info.menu.public_form_description}}</div>
                                    </div>
        
                                    <div *ngIf="loading;then loading_block else loaded_block"></div>
                                    <ng-template #loading_block>
                                        <div class="sk-circle">
                                            <div class="loader">Loading...</div>
                                        </div>
                                    </ng-template>
                                    <ng-template #loaded_block>
                                        <!--                                <ng-container *ngIf="loading_reflect_forms">-->
                                        <!--                                    <div class="sk-circle">-->
                                        <!--                                        <div class="loader">Loading...</div>-->
                                        <!--                                    </div>-->
                                        <!--                                </ng-container>-->
                                        <ng-container *ngIf="data==null">
                                            <div class="data_not_found" style="padding:40px;text-align:center">
                                                DATA NOT FOUND
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="data!=null">
                                            <div *ngIf="is_dataset_edit && data.child_data_by_table['dataset_field'].length==0 && dataset_edit_mode!='grant' && !isDesktop"
                                                 class="p-3">項目がありません。
                                            </div>
                                            <div *ngIf="is_dataset_edit && data.child_data_by_table['dataset_field'].length==0 && dataset_edit_mode!='grant' && isDesktop"
                                                 class="p-3 no-field-list">
                                                 ドラッグ&ドロップで項目を追加してください
                                                 <div class="example-drag">
                                                    <button class="btn btn-lg btn-block btn-light-gray field-drag" type="button">
                                                        <i class="fa-drugger fa-list-ol fa-solid"></i>
                                                        <span> 自動採番 </span>
                                                        <svg style="float: right;"  width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <mask id="mask0_2_1569" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="14"> <path d="M8 0.791992H0V13.792H8V0.791992Z" fill="white" /> </mask> <g mask="url(#mask0_2_1569)"> <path d="M1.5 3.79199C2.32843 3.79199 3 3.12042 3 2.29199C3 1.46357 2.32843 0.791992 1.5 0.791992C0.671573 0.791992 0 1.46357 0 2.29199C0 3.12042 0.671573 3.79199 1.5 3.79199Z" fill="#374767" /> <path d="M6.5 3.79199C7.32843 3.79199 8 3.12042 8 2.29199C8 1.46357 7.32843 0.791992 6.5 0.791992C5.67157 0.791992 5 1.46357 5 2.29199C5 3.12042 5.67157 3.79199 6.5 3.79199Z" fill="#374767" /> <path d="M1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 0 6.46357 0 7.29199C0 8.12042 0.671573 8.79199 1.5 8.79199Z" fill="#374767" /> <path d="M6.5 8.79199C7.32843 8.79199 8 8.12042 8 7.29199C8 6.46357 7.32843 5.79199 6.5 5.79199C5.67157 5.79199 5 6.46357 5 7.29199C5 8.12042 5.67157 8.79199 6.5 8.79199Z" fill="#374767" /> <path d="M1.5 13.792C2.32843 13.792 3 13.1204 3 12.292C3 11.4636 2.32843 10.792 1.5 10.792C0.671573 10.792 0 11.4636 0 12.292C0 13.1204 0.671573 13.792 1.5 13.792Z" fill="#374767" /> <path d="M6.5 13.792C7.32843 13.792 8 13.1204 8 12.292C8 11.4636 7.32843 10.792 6.5 10.792C5.67157 10.792 5 11.4636 5 12.292C5 13.1204 5.67157 13.792 6.5 13.792Z" fill="#374767" /> </g> </svg>
                                                        <!-- <i _ngcontent-rgr-c142="" class="fa fa-th" style="float: right;"></i> -->
                                                    </button>
                                                    <svg width="13" height="18" top="1.25px" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.00488281 1.15625V15.6406C0.00488281 16.1172 0.391602 16.5 0.864258 16.5C1.11035 16.5 1.34863 16.3945 1.5127 16.207L4.73926 12.5156L7.00879 17.0586C7.31738 17.6758 8.06738 17.9258 8.68457 17.6172C9.30176 17.3086 9.55176 16.5586 9.24316 15.9414L7.02832 11.5H11.6416C12.1182 11.5 12.5049 11.1133 12.5049 10.6367C12.5049 10.3906 12.3994 10.1562 12.2158 9.99219L1.5127 0.480469C1.34473 0.332031 1.13379 0.25 0.911133 0.25C0.411133 0.25 0.00488281 0.65625 0.00488281 1.15625Z" fill="#5C5C5C"/>
                                                    </svg>
                                                        
                                                 </div>
                                            </div>
        
                                            <!-- MAIN FORM START -->
        
                                            <ng-container>
                                                <form enctype="multipart/form-data" class="form-horizontal">
                                                    <admin-forms [customFilter]="customFilter" [table_info]="table_info" [fields]="isFieldEditMode()?converted_fields:fields" [data]="data"
                                                                 [forms]="isFieldEditMode()?converted_forms:forms" [grant_menu_a]="grant_menu_a" [error_a]="error_a" [mode]="mode"
                                                                 [is_setting]="is_setting"
                                                                 [is_custom_table_definition]="is_dataset_edit" [parent]="getThis()"
                                                                 [is_add_new_button_select_other_table]="!IS_IFRAME_MODE && is_add_new_button_select_other_table"
                                                                 [dataset_edit_mode]="dataset_edit_mode"
                                                                 [IS_IFRAME_MODE]="IS_IFRAME_MODE"
                                                                 (valueChanged)="changed()"
                                                                 [IS_PUBLIC_FORM]="IS_PUBLIC_FORM"
                                                                 [IS_EMBED_MODE]="IS_EMBED_MODE"
                                                                 (onLoadReflectForms)="onLoadReflectForms($event)"
                                                                 [grant_kind]="grant_kind"
                                                                 [is_from_dashboard]="is_from_dashboard"
                                                                 [is_permission_setting]="is_permission_setting"
                                                    >
                                                    </admin-forms>
                                                </form>
                                            </ng-container>
                                            <!-- MAIN FORM END -->
                                            <div class="pl-3" *ngIf="(table=='admin' || table=='dataset__' + this._share.admin_table_num) && mode=='add'">
                                                <input type="checkbox" [(ngModel)]="sendMailFlg" class="pg-checkbox" id="send_mail">
                                                <label class="checkbox-custom" for="send_mail">
                                                    <ng-container>
                                                        新規ユーザーにメールを送信する
                                                    </ng-container>
                                                </label>
                                            </div>
                                            
                                        </ng-container>
                                    </ng-template>
                                </div>
                                <!-- Start drag field list column -->
                                <div class="col-3 pl-0" *ngIf="isFieldEditMode() && isDesktop" [ngStyle]="getSidebarFieldListsCss()">
                                  
                                    <div style="display:flex;justify-content:end;position: sticky;top: 0;" [ngStyle]="!show_drag_field_list?{width:'100px'}:''">
    
                                        <div class="toggle-drag-field-button" >
                                            <!-- <i class="fa fa-chevron-right" *ngIf="show_drag_field_list" ></i>
                                            <i class="fa fa-chevron-left" *ngIf="!show_drag_field_list" ></i> -->
                                        </div>
                                        <!-- {{show_drag_field_list}} -->
                                        <div (scroll)="onScrollSidebar($event)" class="toggle-drag-field-list" style="display:block;width: 100%;overflow-y: scroll;max-height: 100vh;" [ngClass]="{'mr-3':show_drag_field_list}">
                                            <div
                                               
                                                id="dragSidebarList"
                                                cdkDropList
                                                cdkDropListSortingDisabled
                                                [cdkDropListData]="settingTypes"
                                                [cdkDropListEnterPredicate]="disallowDropPredicate"
                                                [cdkDropListConnectedTo]="connectedLists"
                                                [ngStyle]="!show_drag_field_list ? {width:'60px'}:{}"
                                                >
                                               
                                                <div *ngFor="let type of settingTypes"
                                                    cdkDrag
                                                    [cdkDragData]="type"
                                                    (cdkDragMoved)="dragMoved($event)"
                                                    (cdkDragReleased)="dragReleased($event)"
                                                    (cdkDragStarted)="drag_placeholder_visible=true"
                                                    (cdkDragEnded)="drag_placeholder_visible=false"
                                                    class="field-drag">
                                                    
                                                    <button
                                                        *ngIf="(type.show_list!==false)"
                                                        [disabled]="(settingType == null && (isExistFieldSelected() && !canConvertField(type.Comment))) "
                                                        class="btn btn-lg btn-block btn-light-gray " type="button">
                                                        <i class="fa-drugger fa-solid fa-{{type.icon}}"></i> <span *ngIf="show_drag_field_list" class="type-label"> {{ type.label }}</span>
                                                        <!-- <i class="fa fa-th" style="float: right;"></i> -->
                                                        <svg style="float: right;" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <mask id="mask0_2_1569" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="14"> <path d="M8 0.791992H0V13.792H8V0.791992Z" fill="white" /> </mask> <g mask="url(#mask0_2_1569)"> <path d="M1.5 3.79199C2.32843 3.79199 3 3.12042 3 2.29199C3 1.46357 2.32843 0.791992 1.5 0.791992C0.671573 0.791992 0 1.46357 0 2.29199C0 3.12042 0.671573 3.79199 1.5 3.79199Z" fill="#374767" /> <path d="M6.5 3.79199C7.32843 3.79199 8 3.12042 8 2.29199C8 1.46357 7.32843 0.791992 6.5 0.791992C5.67157 0.791992 5 1.46357 5 2.29199C5 3.12042 5.67157 3.79199 6.5 3.79199Z" fill="#374767" /> <path d="M1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 0 6.46357 0 7.29199C0 8.12042 0.671573 8.79199 1.5 8.79199Z" fill="#374767" /> <path d="M6.5 8.79199C7.32843 8.79199 8 8.12042 8 7.29199C8 6.46357 7.32843 5.79199 6.5 5.79199C5.67157 5.79199 5 6.46357 5 7.29199C5 8.12042 5.67157 8.79199 6.5 8.79199Z" fill="#374767" /> <path d="M1.5 13.792C2.32843 13.792 3 13.1204 3 12.292C3 11.4636 2.32843 10.792 1.5 10.792C0.671573 10.792 0 11.4636 0 12.292C0 13.1204 0.671573 13.792 1.5 13.792Z" fill="#374767" /> <path d="M6.5 13.792C7.32843 13.792 8 13.1204 8 12.292C8 11.4636 7.32843 10.792 6.5 10.792C5.67157 10.792 5 11.4636 5 12.292C5 13.1204 5.67157 13.792 6.5 13.792Z" fill="#374767" /> </g> </svg>
    
                                                    </button>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                                <!-- End Drag Field list column -->
                            </div>
                        </ng-template>
                        <!-- Tab start -->
                        <tab heading="テーブル構成" (selectTab)="dataset_edit_mode='dataset_field'">
                            
                        </tab>
                        <tab  heading="権限設定" (selectTab)="dataset_edit_mode='grant'">
                        </tab>
                        <tab heading="ワークフロー設定" (selectTab)="dataset_edit_mode='workflow'">
                            
                        </tab>
                        <tab heading="画面表示設定" (selectTab)="dataset_edit_mode='screen_display'">
                            
                        </tab>
                        <tab heading="アップロード/エクスポート設定" (selectTab)="dataset_edit_mode='csv'">
                          
                        </tab>
                        <tab heading="その他の設定" (selectTab)="dataset_edit_mode='other'">
                           
                        </tab>
                        <!-- Tab end -->
                    </tabset>
                    <ng-template #not_dataset_edit>
                        <div class="row">
                            <div class="col-9" cdkScrollable
                                [ngClass]="{
                                    'col-12':!isFieldEditMode() || !isDesktop,
                                    'drag-drop-edit-mode pr-0':isFieldEditMode() && isDesktop,
                                    'drag-drop-mode pr-0':isFieldEditMode() && isDesktop && show_drag_field_list,
                                    'drag_start':drag_placeholder_visible && isFieldEditMode() && isDesktop && show_drag_field_list,
                                    'pt-4':isFieldEditMode()}
                                "
                                [ngStyle]="getFieldListsCss()"
                                >
                                <div *ngIf="IS_PUBLIC_FORM && table_info" class="iframe-ex">
                                    <h2 class="iframe-title">{{table_info.menu.public_form_title}}</h2>
                                    <div class="iframe-description">{{table_info.menu.public_form_description}}</div>
                                </div>

                                <div *ngIf="loading;then loading_block else loaded_block"></div>
                                <ng-template #loading_block>
                                    <div class="sk-circle">
                                        <div class="loader">Loading...</div>
                                    </div>
                                </ng-template>
                                <ng-template #loaded_block>
                                    <!--                                <ng-container *ngIf="loading_reflect_forms">-->
                                    <!--                                    <div class="sk-circle">-->
                                    <!--                                        <div class="loader">Loading...</div>-->
                                    <!--                                    </div>-->
                                    <!--                                </ng-container>-->
                                    <ng-container *ngIf="data==null">
                                        <div class="data_not_found" style="padding:40px;text-align:center">
                                            DATA NOT FOUND
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="data!=null">
                                        <div *ngIf="is_dataset_edit && data.child_data_by_table['dataset_field'].length==0 && dataset_edit_mode!='grant' && !isDesktop"
                                            class="p-3">項目がありません。
                                        </div>
                                        <div *ngIf="is_dataset_edit && data.child_data_by_table['dataset_field'].length==0 && dataset_edit_mode!='grant' && isDesktop"
                                            class="p-3 no-field-list">
                                            ドラッグ&ドロップで項目を追加してください
                                            <div class="example-drag">
                                                <button class="btn btn-lg btn-block btn-light-gray field-drag" type="button">
                                                    <i class="fa fa-list-ol"></i>
                                                    <span> 自動採番 </span>
                                                    <svg style="float: right;"  width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <mask id="mask0_2_1569" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="14"> <path d="M8 0.791992H0V13.792H8V0.791992Z" fill="white" /> </mask> <g mask="url(#mask0_2_1569)"> <path d="M1.5 3.79199C2.32843 3.79199 3 3.12042 3 2.29199C3 1.46357 2.32843 0.791992 1.5 0.791992C0.671573 0.791992 0 1.46357 0 2.29199C0 3.12042 0.671573 3.79199 1.5 3.79199Z" fill="#374767" /> <path d="M6.5 3.79199C7.32843 3.79199 8 3.12042 8 2.29199C8 1.46357 7.32843 0.791992 6.5 0.791992C5.67157 0.791992 5 1.46357 5 2.29199C5 3.12042 5.67157 3.79199 6.5 3.79199Z" fill="#374767" /> <path d="M1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 0 6.46357 0 7.29199C0 8.12042 0.671573 8.79199 1.5 8.79199Z" fill="#374767" /> <path d="M6.5 8.79199C7.32843 8.79199 8 8.12042 8 7.29199C8 6.46357 7.32843 5.79199 6.5 5.79199C5.67157 5.79199 5 6.46357 5 7.29199C5 8.12042 5.67157 8.79199 6.5 8.79199Z" fill="#374767" /> <path d="M1.5 13.792C2.32843 13.792 3 13.1204 3 12.292C3 11.4636 2.32843 10.792 1.5 10.792C0.671573 10.792 0 11.4636 0 12.292C0 13.1204 0.671573 13.792 1.5 13.792Z" fill="#374767" /> <path d="M6.5 13.792C7.32843 13.792 8 13.1204 8 12.292C8 11.4636 7.32843 10.792 6.5 10.792C5.67157 10.792 5 11.4636 5 12.292C5 13.1204 5.67157 13.792 6.5 13.792Z" fill="#374767" /> </g> </svg>
                                                    <!-- <i _ngcontent-rgr-c142="" class="fa fa-th" style="float: right;"></i> -->
                                                </button>
                                                <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.85996 15.1692V3.92901C4.86022 3.29045 5.11399 2.67815 5.56552 2.22662C6.01705 1.77509 6.62942 1.52132 7.26798 1.52105C7.57393 1.50037 7.88086 1.54241 8.17008 1.64435C8.45929 1.74628 8.72468 1.90612 8.95004 2.11407C9.17541 2.32203 9.356 2.57371 9.48081 2.85382C9.60561 3.13392 9.67201 3.4366 9.67593 3.74322V7.14313V3.93219C9.67593 0.262187 14.493 0.262187 14.493 3.93219V7.14313V4.77813C14.493 1.10813 19.31 1.10813 19.31 4.77813V7.14313V6.22516C19.31 2.55516 24.127 2.55516 24.127 6.22516V19.9871C24.127 24.0011 21.5579 26.4092 17.7049 26.4092H10.2849C9.83994 26.4093 9.39984 26.3167 8.99252 26.1375C8.58521 25.9583 8.21962 25.6963 7.91898 25.3682L1.84396 18.74C1.33721 18.1875 1.0398 17.4752 1.00327 16.7264C0.966737 15.9775 1.19339 15.2394 1.64395 14.6402L4.86197 10.3492" fill="white" /> <path d="M4.85996 15.1692V3.92901C4.86022 3.29045 5.11399 2.67815 5.56552 2.22662C6.01705 1.77509 6.62942 1.52132 7.26798 1.52105C7.57393 1.50037 7.88086 1.54241 8.17008 1.64435C8.45929 1.74628 8.72468 1.90612 8.95004 2.11407C9.17541 2.32203 9.356 2.57371 9.48081 2.85382C9.60561 3.13392 9.67201 3.4366 9.67593 3.74322V7.14313V3.93219C9.67593 0.262187 14.493 0.262187 14.493 3.93219V7.14313V4.77813C14.493 1.10813 19.31 1.10813 19.31 4.77813V7.14313V6.22516C19.31 2.55516 24.127 2.55516 24.127 6.22516V19.9871C24.127 24.0011 21.5579 26.4092 17.7049 26.4092H10.2849C9.83995 26.4093 9.39984 26.3167 8.99252 26.1375C8.58521 25.9583 8.21962 25.6963 7.91898 25.3682L1.84396 18.74C1.33721 18.1875 1.0398 17.4752 1.00327 16.7264C0.966737 15.9775 1.19339 15.2394 1.64395 14.6402L4.86197 10.3492" stroke="#374767" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                                            </div>
                                        </div>

                                        <!-- MAIN FORM START -->

                                        <ng-container>
                                            <!-- record add form -->
                                            <form enctype="multipart/form-data" class="form-horizontal">
                                                <admin-forms [customFilter]="customFilter" [table_info]="table_info" [fields]="isFieldEditMode()?converted_fields:fields" [data]="data"
                                                            [forms]="isFieldEditMode()?converted_forms:forms" [grant_menu_a]="grant_menu_a" [error_a]="error_a" [mode]="mode"
                                                            [is_setting]="is_setting"
                                                            [is_custom_table_definition]="is_dataset_edit" [parent]="getThis()"
                                                            [is_add_new_button_select_other_table]="!IS_IFRAME_MODE && is_add_new_button_select_other_table"
                                                            [dataset_edit_mode]="dataset_edit_mode"
                                                            [IS_IFRAME_MODE]="IS_IFRAME_MODE"
                                                            (valueChanged)="changed()"
                                                            [IS_PUBLIC_FORM]="IS_PUBLIC_FORM"
                                                            [IS_EMBED_MODE]="IS_EMBED_MODE"
                                                            (onLoadReflectForms)="onLoadReflectForms($event)"
                                                            [grant_kind]="grant_kind"
                                                            [is_from_dashboard]="is_from_dashboard"
                                                            [is_permission_setting]="is_permission_setting"
                                                >
                                                </admin-forms>
                                            </form>
                                        </ng-container>
                                        <!-- MAIN FORM END -->
                                        <div class="pl-3" *ngIf="(table=='admin' || table=='dataset__' + this._share.admin_table_num) && mode=='add'">
                                            <input type="checkbox" [(ngModel)]="sendMailFlg" class="pg-checkbox" id="send_mail">
                                            <label class="checkbox-custom" for="send_mail">
                                                <ng-container>
                                                    新規ユーザーにメールを送信する
                                                </ng-container>
                                            </label>
                                        </div>
                                        <!-- CHILD FORM START -->
                                       
                                        <ng-container *ngFor="let child of data.getFilteredChildAry(this.customFilter);let child_i = index">
                                            <div class="mt-4"
                                                *ngIf="isDisplayChild(child_i,child)"
                                                id="child-{{child.table}}" class="pfc-child-container mt-2">
                                                
                                                <ng-template [ngIf]="child.menu && child.menu.multiple_mode=='normal'">
                                                   
                                                        <label class="pl-4">
                                                           
                                                            <span class="fw-bold fs-13 child-add-span">
                                                                <i class="fa-database fa-drugger fa-solid"></i>
                                                                {{child.getLabel()}}

                                                                <i class="fa-solid fa-circle-question mr-1"
                                                                aria-hidden="true" ngbTooltip="" container="body"></i>
                                                            </span>
                                                           

                                                            <button [disabled]="!canAddChildData(child)" class="btn btn-primary ml-3 btn-sm add-child-btn" type="button" style="margin-top: -3px;"
                                                                    (click)="addChildData(child, undefined, undefined, data.child_data_by_table[child.table]?.length)">
                                                                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6 0.875C6 0.460156 5.66484 0.125 5.25 0.125C4.83516 0.125 4.5 0.460156 4.5 0.875V4.25H1.125C0.710156 4.25 0.375 4.58516 0.375 5C0.375 5.41484 0.710156 5.75 1.125 5.75H4.5V9.125C4.5 9.53984 4.83516 9.875 5.25 9.875C5.66484 9.875 6 9.53984 6 9.125V5.75H9.375C9.78984 5.75 10.125 5.41484 10.125 5C10.125 4.58516 9.78984 4.25 9.375 4.25H6V0.875Z" fill="white"/>
                                                                    </svg>
                                                                        
                                                                    {{child.getLabel()}}を追加
    
                                                            </button>
                                                        </label>
                                                   
                                                    <div *ngFor="let c_data of data.child_data_by_table[child.table];let i = index" [attr.data-index]="i"
                                                        class="child-container">
                                                        <div class="flex">
                                                            <div [ngClass]="show_google_calendar ? 'w-75' : 'w-100'">
                                                                <button class="btn btn-default btn-sm btn-child-order mr-2" (click)="orderChange(data.child_data_by_table[child.table],i,i-1)"
                                                                    *ngIf="child.forms.byFieldName(child.order_field)!=undefined">

                                                                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.02949 0.970215C4.73652 0.677246 4.26074 0.677246 3.96777 0.970215L0.217773 4.72021C-0.0751953 5.01318 -0.0751953 5.48896 0.217773 5.78193C0.510742 6.0749 0.986523 6.0749 1.27949 5.78193L3.7498 3.30928V10.4999C3.7498 10.9147 4.08496 11.2499 4.4998 11.2499C4.91465 11.2499 5.2498 10.9147 5.2498 10.4999V3.30928L7.72012 5.77959C8.01309 6.07256 8.48887 6.07256 8.78184 5.77959C9.07481 5.48662 9.07481 5.01084 8.78184 4.71787L5.03184 0.967871L5.02949 0.970215Z" fill="#5C5C5C"/>
                                                                    </svg>
                                                                        
                                                                    上に移動
                                                                </button>
                                                                <button class="btn btn-default btn-sm btn-child-order" (click)="orderChange(data.child_data_by_table[child.table],i,i+1)"
                                                                    *ngIf="child.forms.byFieldName(child.order_field)!=undefined">
                                                                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.97051 11.0298C4.26348 11.3228 4.73926 11.3228 5.03223 11.0298L8.78223 7.27979C9.0752 6.98682 9.0752 6.51104 8.78223 6.21807C8.48926 5.9251 8.01348 5.9251 7.72051 6.21807L5.2502 8.69072L5.2502 1.5001C5.2502 1.08525 4.91504 0.750097 4.5002 0.750097C4.08535 0.750097 3.7502 1.08525 3.7502 1.5001L3.7502 8.69072L1.27988 6.22041C0.986914 5.92744 0.511132 5.92744 0.218163 6.22041C-0.0748053 6.51338 -0.0748053 6.98916 0.218163 7.28213L3.96816 11.0321L3.97051 11.0298Z" fill="#5C5C5C"/>
                                                                    </svg>
                                                                        
                                                                    下に移動
                                                                </button>
                                                                <span class="pull-right delete-child" (click)="deleteChild(child,i)">
                                                                    削除
                                                                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.76324 1.36623C8.07559 1.05388 8.07559 0.546619 7.76324 0.234265C7.45088 -0.0780884 6.94362 -0.0780884 6.63127 0.234265L4 2.86803L1.36623 0.236764C1.05388 -0.0755896 0.546619 -0.0755896 0.234265 0.236764C-0.0780884 0.549118 -0.0780884 1.05638 0.234265 1.36873L2.86803 4L0.236764 6.63376C-0.0755895 6.94612 -0.0755895 7.45338 0.236764 7.76573C0.549118 8.07809 1.05638 8.07809 1.36873 7.76573L4 5.13197L6.63376 7.76324C6.94612 8.07559 7.45338 8.07559 7.76573 7.76324C8.07809 7.45088 8.07809 6.94362 7.76573 6.63127L5.13197 4L7.76324 1.36623Z" fill="#5C5C5C" fill-opacity="0.5"/>
                                                                    </svg>
                                                                        
                                                                </span>
                                                                <!-- <button class="btn btn-sm btn-danger pull-right z-1 mr-2" (click)="deleteChild(child,i)">
                                                                    削除
                                                                </button>
                                                                <button class="btn btn-sm btn-info pull-right z-1 mr-2" (click)="duplicateChild(child,i)">
                                                                    複製
                                                                </button> -->
                                                               
                                                                <ng-container *ngIf="child.forms as c_forms">
                                                                    <admin-forms [parent]="getThis()" [table_info]="child" [fields]="child.fields" [forms]="c_forms"
                                                                        [data]="c_data"
                                                                        [siblings]="data.child_data_by_table[child.table]"
                                                                        [error_a]="child_error_a_by_table[child.table][i]?child_error_a_by_table[child.table][i]:{}"
                                                                        [mode]="mode" [id_prefix]="child.table + '_cindex_' + i + '_'"
                                                                        [is_custom_table_definition]="is_dataset_edit" [IS_PUBLIC_FORM]="IS_PUBLIC_FORM"
                                                                        [IS_EMBED_MODE]="IS_EMBED_MODE" [child_index]="i"
                                                                        [parent_data]="data"
                                                                        [IS_IFRAME_MODE]="IS_IFRAME_MODE"
                                                                        (onLoadReflectFormsFromChild)="onLoadReflectFormsFromChild($event)"
                                                                        [is_from_dashboard]="is_from_dashboard"
                                                                        [is_permission_setting]="is_permission_setting"
                                                                        ></admin-forms>
                                                                </ng-container>
                                                            </div>
                                                            <google-calendar [c_data]="c_data" [table_info]="table_info" [forms]="forms" [data]="data" ></google-calendar>
                                                        </div>
                                                    </div>
                                                    <button *ngIf="data.child_data_by_table[child.table] && data.child_data_by_table[child.table].length>0 "
                                                            [disabled]="!canAddChildData(child)"
                                                            class="btn btn-primary ml-3 btn-sm add-child-btn"
                                                            type="button" style="margin-top: -3px;"
                                                            (click)="addChildData(child, undefined, undefined, data.child_data_by_table[child.table]?.length)">
                                                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6 0.875C6 0.460156 5.66484 0.125 5.25 0.125C4.83516 0.125 4.5 0.460156 4.5 0.875V4.25H1.125C0.710156 4.25 0.375 4.58516 0.375 5C0.375 5.41484 0.710156 5.75 1.125 5.75H4.5V9.125C4.5 9.53984 4.83516 9.875 5.25 9.875C5.66484 9.875 6 9.53984 6 9.125V5.75H9.375C9.78984 5.75 10.125 5.41484 10.125 5C10.125 4.58516 9.78984 4.25 9.375 4.25H6V0.875Z" fill="white"/>
                                                            </svg>
                                                                
                                                            {{child.getLabel()}}を追加する

                                                    </button>
                                                </ng-template>

                                            </div>
                                        </ng-container>
                                        <!-- CHILD FORM END -->

                                    </ng-container>
                                </ng-template>
                            </div>
                            <!-- Start drag field list column -->
                            <div class="col-3 pl-0" *ngIf="isFieldEditMode() && isDesktop" [ngStyle]="getSidebarFieldListsCss()">
                                <div style="display:flex;justify-content:end;position: sticky;top: 0;">

                                    <div class="toggle-drag-field-button" (click)="toggleDragFieldList()" [ngStyle]="!show_drag_field_list?{width:'27px'}:''">
                                        <i class="fa fa-chevron-right" *ngIf="show_drag_field_list" ></i>
                                        <i class="fa fa-chevron-left" *ngIf="!show_drag_field_list" ></i>
                                    </div>
                                    <div (scroll)="onScrollSidebar($event)" class="toggle-drag-field-list" style="display:block;width: 100%;overflow-y: scroll;max-height: 100vh;" *ngIf="show_drag_field_list">
                                        <div
                                            id="dragSidebarList"
                                            cdkDropList
                                            cdkDropListSortingDisabled
                                            [cdkDropListData]="settingTypes"
                                            [cdkDropListEnterPredicate]="disallowDropPredicate"
                                            [cdkDropListConnectedTo]="connectedLists"

                                            >
                                            <div *ngFor="let type of settingTypes"
                                                cdkDrag
                                                [cdkDragData]="type"
                                                (cdkDragMoved)="dragMoved($event)"
                                                (cdkDragReleased)="dragReleased($event)"
                                                (cdkDragStarted)="drag_placeholder_visible=true"
                                                (cdkDragEnded)="drag_placeholder_visible=false"
                                                class="field-drag">
                                                <button
                                                    *ngIf="(type.show_list!==false)"
                                                    [disabled]="(settingType == null && (isExistFieldSelected() && !canConvertField(type.Comment))) "
                                                    class="btn btn-lg btn-block btn-light-gray " type="button">
                                                    <i class="fa fa-{{type.icon}}"></i> <span> {{ type.label }}</span>
                                                    <!-- <i class="fa fa-th" style="float: right;"></i> -->
                                                    <svg style="float: right;" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <mask id="mask0_2_1569" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="14"> <path d="M8 0.791992H0V13.792H8V0.791992Z" fill="white" /> </mask> <g mask="url(#mask0_2_1569)"> <path d="M1.5 3.79199C2.32843 3.79199 3 3.12042 3 2.29199C3 1.46357 2.32843 0.791992 1.5 0.791992C0.671573 0.791992 0 1.46357 0 2.29199C0 3.12042 0.671573 3.79199 1.5 3.79199Z" fill="#374767" /> <path d="M6.5 3.79199C7.32843 3.79199 8 3.12042 8 2.29199C8 1.46357 7.32843 0.791992 6.5 0.791992C5.67157 0.791992 5 1.46357 5 2.29199C5 3.12042 5.67157 3.79199 6.5 3.79199Z" fill="#374767" /> <path d="M1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 0 6.46357 0 7.29199C0 8.12042 0.671573 8.79199 1.5 8.79199Z" fill="#374767" /> <path d="M6.5 8.79199C7.32843 8.79199 8 8.12042 8 7.29199C8 6.46357 7.32843 5.79199 6.5 5.79199C5.67157 5.79199 5 6.46357 5 7.29199C5 8.12042 5.67157 8.79199 6.5 8.79199Z" fill="#374767" /> <path d="M1.5 13.792C2.32843 13.792 3 13.1204 3 12.292C3 11.4636 2.32843 10.792 1.5 10.792C0.671573 10.792 0 11.4636 0 12.292C0 13.1204 0.671573 13.792 1.5 13.792Z" fill="#374767" /> <path d="M6.5 13.792C7.32843 13.792 8 13.1204 8 12.292C8 11.4636 7.32843 10.792 6.5 10.792C5.67157 10.792 5 11.4636 5 12.292C5 13.1204 5.67157 13.792 6.5 13.792Z" fill="#374767" /> </g> </svg>

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Drag Field list column -->
                        </div>
                    </ng-template>
                </div>
                <div class="card-footer text-right" *ngIf="data!=null && table_info!=undefined && !is_permission_setting  && !loading">
                    <button *ngIf="!IS_IFRAME_MODE" class="btn btn-default btn-ladda" data-style="expand-right" [ladda]='sending' type="button" (click)="cancel()">
                        キャンセル
                    </button>
                    &nbsp;&nbsp;
                    <button class="btn btn-primary btn-ladda" data-style="expand-right" [ladda]='sending' type="submit"
                            (click)="go_edit()" [ngClass]="{'btn-outline-primary':table_info?.menu?.is_workflow && !IS_IFRAME_MODE}"
                            [ngStyle]="{
                                'border': table_info?.menu?.is_workflow && !IS_IFRAME_MOD ? '2px solid #20A8D7' : ''
                              }"
                            >
                        <div *ngIf="isWorkflowApplyAvailable();then workflow_block;else non_workflow_block"></div>
                        <ng-template #workflow_block>
                            <ng-container *ngIf="IS_IFRAME_MODE">
                                <span>保存</span>
                            </ng-container>
                            <ng-container *ngIf="!IS_IFRAME_MODE">
                                <span>下書き保存</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #non_workflow_block>
                            <ng-container *ngIf="IS_IFRAME_MODE || IS_EMBED_MODE;else NOT_IFRAME">
                                <span>{{submit_text}}</span>
                            </ng-container>
                            <ng-template #NOT_IFRAME>
                                <span *ngIf="mode=='add' && is_dataset_edit" >テーブルを保存</span>
                                <span *ngIf="mode=='add' && !is_dataset_edit">作成</span>
                                <span *ngIf="mode=='edit'">作成</span>
                            </ng-template>
                        </ng-template>
                    </button>
                    &nbsp;
                    <button class="btn btn-primary btn-ladda" data-style="expand-right" [ladda]='sending' type="submit"
                            (click)="workflow_apply_start()" *ngIf="isWorkflowApplyAvailable() && !IS_IFRAME_MODE">
                        <span>申請</span>
                    </button>
                </div>
            </div>
        </div>
        <!--/.col-->
    </div>
    <!--/.row-->
    <div class="d-none d-sm-block" *ngIf="isQuestionnaireStyle() && !IS_PUBLIC_FORM" style="position: fixed;right:10px;bottom:10px;">
        <button type="button" class="btn btn-primary" (click)="go_edit()" [ladda]='sending'>保存する</button>
    </div>
    <div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-warning" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h4>
                    <button type="button" class="close" (click)="editModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p *ngIf="mode=='add'">本当に追加してもよろしいですか？</p>
                    <p *ngIf="mode=='edit'">本当に更新してもよろしいですか？</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="editModal.hide()">キャンセル</button>
                    <button type="button" class="btn btn-warning" *ngIf="mode=='add'" (click)="add()">追加する</button>
                    <button type="button" class="btn btn-warning" *ngIf="mode=='edit'" (click)="edit()">更新する</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

</ng-template>

<ng-container *ngIf="table_info && table_info?.menu && table_info?.menu?.is_workflow">
    <div bsModal #worflowModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
        <workflow-modal [table_info]="table_info" [workflow_base]="workflow" [mode]="mode" [data_id]="id"
                        (valueChanged)="workflowChanged($event)" (applied)="workflow_store()" [parent]="getThis()"
                        [sending]="sending" [postFormData]="postFormData" [data_changed]="data.last_dirty_changed"></workflow-modal>

    </div><!-- /.modal -->
</ng-container>

<ng-container *ngIf="table_info && table_info?.menu && table_info?.menu?.popup_comment_after_save">
    <div bsModal #commentModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
        <comment-modal #commentModalComponent (valueChanged)="commentChanged($event)" (applied)="store()"
                       [parent]="getThis()" [sending]="sending"></comment-modal>

    </div><!-- /.modal -->
</ng-container>

<confirm-modal #childDeltedConfirmModal [type]="'warning'" [submit_button_text]="'削除する'" [text]="'本当に削除してもよろしいですか？'"
    (onOk)="clickToDeleteChild(childDeleteData.child,childDeleteData.index, true)"></confirm-modal>
    
<confirm-modal #publicFormConfirmModal [submit_button_text]="'はい'" [text]="'送信してよろしいですか？'" (onOk)="store()"
               [sending]="sending" [is_iframe]="true"></confirm-modal>